import React from "react";
import Header from "./Header";

import { v4 as uuid } from "uuid";

export default function Ranks() {
  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Rank</th>
              <th>Attained when:</th>
              <th>Unlocks:</th>
            </tr>
          </thead>
          <tbody>
              <Rank
                name="Trainee"
                attained={"On approval into WAVA."}
                unlocks={["A hub", "DC-10F"]}
              />
              <Rank
                name="Third Officer"
                attained={"10 hours flight time AND passing the WAVA entry test"}
                unlocks={["An additional hub to operate from", "MD-11F"]}
              />
              <Rank
                name="Second Officer"
                attained={"50 hours flight time"}
                unlocks={["The 747 family"]}
              />
              <Rank
                name="First Officer"
                attained={"100 hours flight time"}
                unlocks={["All routes unlocked."]}
              />
              <Rank
                name="Captain"
                attained={"250 hours flight time"}
                unlocks={[
                  "Fly our biggest codeshare (to be decided upon acquiring our first codeshare).",
                ]}
              />
              <Rank
                name="Senior Captain"
                attained={"500 hours flight time"}
                unlocks={["All codeshares unlocked"]}
              />
              <Rank
                name="Blue Pilot"
                attained={"1000 hours flight time"}
                unlocks={["Charter Routes to/from one of our destinations"]}
              />
          </tbody>
        </table>
      </div>
    </>
  );
}

function Rank({ name, attained, unlocks = [] }) {
  return (
    <tr>
      <td>
        <img src={require(`../../assets/epaulettes/${name}.svg`)} />
      </td>
      <td>{name}</td>
      <td>{attained}</td>
      <td>
        <ul>
          {unlocks.map((x) => {
            return <li key={uuid()}>{x}</li>;
          })}
        </ul>
      </td>
    </tr>
  );
}
