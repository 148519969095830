import React from "react";
import "./Navbar.css";

import { ReactComponent as LogoSVG } from "../assets/Logo.svg";

import { Link, useLocation } from "react-router-dom";

export default function Navbar() {
  const { pathname } = useLocation();

  return (
    <div
      class="container-fluid position-relative p-0"
      style={{ background: "rgb(59, 68, 161)" }}
    >
      <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <Link to="/" class="navbar-brand p-0">
          <LogoSVG style={{ width: "75px", height: "75px" }} />
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span class="fa fa-bars"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="navbar-nav ms-auto py-0">
            <Link
              to="/"
              className={`nav-item nav-link ${
                pathname === "/" ? "active" : ""
              }`}
            >
              Home
            </Link>
            <Link
              to="/about"
              className={`nav-item nav-link ${
                pathname === "/about" ? "active" : ""
              }`}
            >
              About Us
            </Link>
            <Link
              to="/staff"
              className={`nav-item nav-link ${
                pathname === "/staff" ? "active" : ""
              }`}
            >
              Our Staff
            </Link>
            <div class="nav-item dropdown">
              <a
                href="#"
                class="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Operations
              </a>
              <div class="dropdown-menu m-0">
                <Link to="/ranks" class="dropdown-item">
                  Rank Structure
                </Link>
                <Link to="/routes" class="dropdown-item">
                  Routes
                </Link>
                <Link to={"/fleet"} class="dropdown-item">
                  Fleet
                </Link>
                <Link to="/hubs" class="dropdown-item">
                  Hubs
                </Link>
              </div>
            </div>
            <a
              href="https://crewcenter.wavacva.xyz/apply"
              class="nav-item nav-link"
            >
              Apply
            </a>
          </div>
          <a
            href="https://crewcenter.wavacva.xyz"
            class="btn btn-primary rounded-pill py-2 px-4 ms-lg-4"
          >
            Crew Center
          </a>
        </div>
      </nav>
    </div>
  );
}
