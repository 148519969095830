import React from "react";
import useIFCProfile from "../../hooks/useIFCProfile";

export default function MeetTheTeam() {
  return (
    <div class="container-fluid guide py-5">
      <div class="container py-5">
        <div class="mx-auto text-center mb-5" style={{ maxWidth: "900px" }}>
          <h5 class="section-title px-3">OUR STAFF</h5>
          <h1 class="mb-0" style={{ color: "#000" }}>
            Meet the Team
          </h1>
          <a
            href="https://ifvarb.com/staff.php"
            style={{ fontStyle: "italic", color: "var(--bs-primary)" }}
          >
            Interested in joining?
          </a>
        </div>
        <div class="row g-4" style={{ justifyContent: "center" }}>
          <TeamMember name={"Ben R"} position={"CEO"} ifc={"Ben.R"} />
          <TeamMember name={"Ethan"} position={"Air Admin"} ifc={"EthanO"} />
          <TeamMember name={"Saf"} position={"Ground Admin"} ifc={"Saf"} />
          <TeamMember
            name={"Zach"}
            position={"Internal Affairs Manager"}
            ifc={"ProSalami"}
          />
        </div>
      </div>
    </div>
  );
}

function TeamMember({ name, position, ifc }) {
  const ifcUser = useIFCProfile(ifc);
  return (
    <div class="col-md-6 col-lg-3">
      <div class="guide-item">
        <div class="guide-img">
          <div class="guide-img-efects">
            <img
              src={ifcUser.profilepic}
              class="img-fluid w-100 rounded-top"
              alt="Image"
            />
          </div>
          <div class="guide-icon rounded-pill p-2">
            <a
              class="btn btn-square btn-primary rounded-circle mx-1"
              href={`https://community.infiniteflight.com/u/${ifc}`}
            >
              IFC
            </a>
          </div>
        </div>
        <div class="guide-title text-center rounded-bottom p-4">
          <div class="guide-title-inner">
            <h4 class="mt-3">{name}</h4>
            <p class="mb-0">{position}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
