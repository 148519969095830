import React from "react";
import AboutSection from "./AboutSection";
import Header from "./Header";

export default function About() {
  return (
    <>
      <Header />
      <AboutSection />
    </>
  );
}
