import React from "react";
import Header from "./Header";
import MeetTheTeam from "./MeetTheTeam";

export default function About() {
  return (
    <>
      <Header />
      <MeetTheTeam />
    </>
  );
}
