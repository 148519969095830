import React from "react";

export default function Header() {
  return (
    <div
      class="container-fluid bg-breadcrumb"
      style={{
        backgroundImage: `url(${require("../../assets/images/header_background.png")})`,
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <div class="container text-center py-5" style={{ maxWidth: "900px" }}>
        <h3 class="text-white display-3 mb-4">Our Staff</h3>
      </div>
    </div>
  );
}
